<template>
  <div class="g-box g-log-list-box" v-loading="loading">
    <div class="m-form-inline" data-m="select">
      <div class="m-form-inline-mn">
        <table-filter
          :show-list="showList" :hide-list="hideList" v-model="formInline"
          @filterChange="toPage(1)"
        ></table-filter>
      </div>
    </div>
    <div class="m-list" data-m="list">
      <m-table-icons @sizeChange="val=>tableSize=val" :columns="tableTileTemp" @columnChange="val=>tableTile=val">
      </m-table-icons>
      <el-table
        :data="tableData" :size="tableSize" stripe
        tooltip-effect="dark"
        ref="multipleTable"
      >
        <el-table-column
          align="center" v-for="item in tableTile" :label="item.columnLabel"
          :prop="item.prop" :key="item.key" :width="item.width"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
    </div>
    <div class="m-page" data-m="list" v-if="formInline.page.total > 0">
      <p class="u-p">总共{{ formInline.page.total }}条数据</p>
      <el-pagination
        layout="prev, pager, next, sizes, jumper"
        :total="formInline.page.total" :page-size.sync="formInline.page.pageSize"
        :current-page="formInline.page.currentPage" @current-change="toPage"
        :page-sizes="[10, 20, 30, 40]" @size-change="toPage(1)"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import api from './../../../api'
import tableFilter from '../../../components/table-filter'
import mTableIcons from '../../../components/m-table-icons'
export default Vue.extend({
  name: 'log-list',
  components: { tableFilter, mTableIcons },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      tableSize: 'medium',
      tableTileTemp: [],
      showList: [{
        copName: 'el-select',
        key: 'type',
        type: '',
        attrs: ['placeholder', 'options'],
        placeholder: '日志类别',
        options: [{
          label: '登录日志',
          value: 'login'
        }, {
          label: '登出日志',
          value: 'logout'
        }, {
          label: '用户操作',
          value: 'operation'
        }]
      }, {
        copName: 'el-input',
        placeholder: '操作人',
        key: 'userName',
        userName: '',
        attrs: ['placeholder']
      }, {
        copName: 'el-date-picker',
        key: 'date',
        date: [],
        attrs: ['copType', 'rangeSeparator', 'startPlaceholder', 'endPlaceholder'],
        copType: 'daterange',
        rangeSeparator: '~',
        startPlaceholder: '日志开始日',
        endPlaceholder: '日志结束日'
      }],
      hideList: [],
      formInline: {
        type: '',
        userName: '',
        date: null,
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      filter: {
        typeOptions: [{
          label: '全部',
          value: ''
        }, {
          label: '登录日志',
          value: 'login'
        }, {
          label: '登出日志',
          value: 'logout'
        }, {
          label: '用户操作',
          value: 'operation'
        }]
      },
      tableData: [],
      tableTile: [{
        key: '1',
        columnLabel: '日志内容',
        prop: 'content',
        width: ''
      }, {
        key: '2',
        columnLabel: '日志类别',
        prop: 'type',
        width: ''
      }, {
        key: '3',
        columnLabel: '操作人',
        prop: 'operator',
        width: ''
      }, {
        key: '4',
        columnLabel: '创建时间',
        prop: 'creationTime',
        width: ''
      }],
      loading: true
    }
  },
  computed: {},
  watch: {},
  beforeCreate () {
  },
  created () {
    this.tableTileTemp = JSON.parse(JSON.stringify(this.tableTile))
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate  () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {
      this.toPage(1)
    },
    onSubmit () {
      this.loading = true
      api.auth.getAuthLogList({
        query: {
          logType: this.formInline.type,
          logCreater: this.formInline.userName,
          startTime: this.formInline.date && this.formInline.date[0] ? this.$moment(this.formInline.date[0]).format('YYYY-MM-DD HH:mm:ss') : '',
          endTime: this.formInline.date && this.formInline.date[1] ? this.$moment(this.formInline.date[1]).format('YYYY-MM-DD HH:mm:ss') : '',
          pageNo: this.formInline.page.currentPage,
          pageSize: this.formInline.page.pageSize
        }
      }).then(res => {
        this.tableData = res.data.map(item => {
          return {
            id: item.logId,
            content: item.logMessage,
            type: item.logType,
            operator: item.logCreater,
            creationTime: this.$moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
            ip: item.ip
          }
        })
        this.formInline.page.total = res.num
        this.loading = false
      })
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    }
  }
})
</script>

<style lang="scss" scoped>
  @import "./../../../assets/css/global-variable";
  .g-log-list-box {
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
  }
</style>

<style>
</style>
